.our-members-main {
    text-align: center;
    overflow-x: hidden;
}

.our-members-slider {
    margin-top: 10px;
}

.our-members-slider img {
    object-fit: cover;
}

.our-members-slider p {
    margin: 0px 5px 0px 5px;
}

.our-members-title {
    text-align: center;
    width: 90%;
    border-bottom-style: solid;
    border-bottom-color: lightgreen;
    margin: 2% 0% 0% 5%;
    padding-bottom: 1%;
}

.our-members-title h1 {
    letter-spacing: 3px;
    color: grey;
}

.our-members-photo {
    width: 300px;
    height: 400px;
    border-radius: 200px;
    pointer-events: none;
    margin: auto;
}

.our-members-prev-button {
    position: absolute;
    top: 50%;
    left: 1px;
    background-color: transparent;
    border: none;
}

.our-members-prev-button img:hover {
   background-color: lightgreen;
   border-radius: 20px;
}

.our-members-next-button {
    position: absolute;
    top: 50%;
    right: 1px;
    background-color: transparent;
    border: none;
}

.our-members-next-button img:hover {
    background-color: lightgreen;
    border-radius: 20px;
}