.slider2 {
  display: flex;
  align-items: center;
  margin: 20px;

  opacity: 0;
  transform: scale(85%);
  transition: 2s;
}

.slider2--zoom {
  opacity: 1;
  transform: scale(100%);
  transition: 2s;
}

.slider2__image_01 {
  margin-left: 5%;
  width: 15%;
  height: 17%;

}

.slider2__image_02 {
  margin-top: 5%;
  margin-left: 5%;
  width: 15%;
  height: 15%;
  border-radius: 0px;
  box-shadow: none;
}

.slider2__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 20px;
  text-align: left;
  font: serif;
}

.slider2__title {
  text-align: left;
  color: #437e5b;
  font: serif;
}

@media screen and (max-width: 800px) {
  .slider2 {
    flex-direction: column;
    background-color: rgb(0,0,0,0);
  }
  .slider2__image_01 {
    width: 35%;
    height: 45%;
  }
  .slider2__image_02 {
    margin-top: -5%;
    width: 30%;
    height: 30%;
    border-radius: 0px;
    box-shadow: none;
  }

}
