.title {
    text-align: center;
    width: 90%;
    border-bottom-style: solid;
    border-bottom-color: lightgreen;
    margin: 2% 0% 0% 5%;
    padding-bottom: 1%;
}

.title h1 {
    letter-spacing: 5px;
    color: grey;
}

.content{
    display: flex;
    flex-direction: row;
}

.content h4 {
    color: #437e5b;
}

.content img {
    width: 30%;
    height: 100%;
    margin: 3% 0% 0% 5%;
    grid-column-start: vline1;
    grid-column-end: vline2;
}

.content p {
    grid-column-start: vline3;
    width: 100%;
    margin-top: 3%;
    margin-right: 3%;
    margin-left: 3%;
}

/* .video {
    margin: 3% 0% 3% 5%;
    display: flex;
    flex-direction: row;
}

.video p{
    grid-column-start: vline3;
    margin-left: 3%;
} */

/* .images{
    align-items: center;
    margin: 2% 0% 0% 5%;
}

.people{
    width: 30%;
    border-radius: 0px;
    margin: 0% 0% 0% 10%;

} */

@media screen and (max-width: 900px) {
    .content {
        display: block;
        width: 90%;
    }
    .content h4{
        text-align: center;
    }

    .content img{
        margin-left: 20%;
        max-width: 80%;
        width: 70%;
        height: auto;
    }

}