.navbar-custom {
    background-color: #3d7f4e;
    
}

.navbar-text {
    font-size: large;
    text-decoration: none;
}

.navbar-text:hover {
    text-decoration: underline;
}

.no-hover:hover {
    text-decoration: none;
}

.navbar-brand {
    margin-left: 3%;
    display: grid;
    grid-template-columns: [vline1] 40px [vline2] 10px [vline3] 50px [vline4];
}


/* #collasible-nav-dropdown::after {
    display: none;
} */

.navbar-brand h3 {
    color: white;
    grid-column-start: vline3;
    grid-column-end: vline4;
    margin-top: 3px;
}

.navbar-brand img {
    width: 40px;
    height: 40px;
    margin: 0px 10px 0px 0px;
    grid-column-start: vline1;
    grid-column-end: vline2;
}
