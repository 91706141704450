.title {
    margin-top: 5%;
    letter-spacing: 5px;
    color: grey;
    text-align: center;
}

.contact {
    align-items: center;
}

.facebookqr{
    width: 10%;
    height: 10%;
    margin: 3% 2% 3% 10%;
    border-radius: 0px;
    box-shadow: none;
}

.instagramqr{
    width: 10%;
    height: 10%;
    margin: 3% 2% 3% 3%;
    border-radius: 0px;
    box-shadow: none;
}

.playstoreqr{
    width: 10%;
    height: 10%;
    margin: 3% 2% 3% 3%;
    border-radius: 0px;
    box-shadow: none;
}

.linkedinqr{
    width: 10%;
    height: 10%;
    margin: 3% 2% 3% 3%;
    border-radius: 0px;
    box-shadow: none;
}

.youtubeqr{
    width: 10%;
    height: 10%;
    margin: 3% 2% 3% 3%;
    border-radius: 0px;
    box-shadow: none;
}

.linktreeqr{
    width: 9%;
    height: 9%;
    margin: 3% 2% 3% 3%;
    border-radius: 0px;
    box-shadow: none;
}

.subtitle {
    margin-top: -1%;
    letter-spacing: 5px;
    color: grey;
    font-size: 200%;
    text-align: center;
}

.mail{
    display: flex;
    justify-content: center;
}
.mailicon{
    width: 5%;
    height: 5%;
    box-shadow: none;
}
.email{
    color: grey;
    letter-spacing: 2px;
    margin-top: 1.5%;
}

@media (max-width: 1200px){ 
    .contact{
        align-items: center;
    }    

    .facebookqr {
        display: flex;
        flex-direction: wrap;
        width: 50%;
        height: 50%;
        margin-left: auto;
        margin-right: auto;
    }
    .instagramqr {
        display: flex;
        flex-direction: wrap;
        width: 50%;
        height: 50%;
        margin-left: auto;
        margin-right: auto;
    }
    .linkedinqr {
        display: flex;
        flex-direction: wrap;
        width: 50%;
        height: 50%;
        margin-left: auto;
        margin-right: auto;
    }
    .playstoreqr {
        display: flex;
        flex-direction: wrap;
        width: 50%;
        height: 50%;
        margin-left: auto;
        margin-right: auto;
    }
    .linktreeqr {
        display: flex;
        flex-direction: wrap;
        width: 45%;
        height: 50%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10%;
    }
    .youtubeqr {
        display: flex;
        flex-direction: wrap;
        width: 50%;
        height: 50%;
        margin-left: auto;
        margin-right: auto;
    }

    .mailicon{
        width:10%;
    }

    .email{
        margin-top: 2%;
    }

}
