.our-mission-title {
    text-align: center;
    width: 90%;
    border-bottom-style: solid;
    border-bottom-color: lightgreen;
    margin: 2% 0% 0% 5%;
    padding-bottom: 1%;
}

.our-mission-title h1 {
    letter-spacing: 4px;
    color: grey;
}

.our-mission-content {
    display: grid;
    grid-template-columns: 3% [vline1] 40% [vline2] 150px [vline3];
}

.our-mission-content img {
    width: 100%;
    height: 600px;
    margin: 7% 0% 0% 5%;
    grid-column-start: vline1;
    grid-column-end: vline2;
}

.our-mission-content p {
    grid-column-start: vline3;
    width: 80%;
    margin-top: 10%;
}