.title {
    text-align: center;
    width: 90%;
    border-bottom-style: solid;
    border-bottom-color: lightgreen;
    margin: 2% 0% 0% 5%;
    padding-bottom: 1%;
}

.title h1 {
    letter-spacing: 5px;
    color: grey;
}

.content {
    display: flex;
    flex-direction: row;
}

.content img {
    width: 20%;
    height: 400px;
    margin: 3% 0% 0% 5%;
    grid-column-start: vline1;
    grid-column-end: vline2;
}

.content p {
    grid-column-start: vline3;
    width: 100%;
    margin-top: 2%;
    margin-right: 3%;
    margin-left: 3%;
}

/* .images{
    align-items: center;
    margin: 2% 0% 0% 5%;
}

.people{
    width: 30%;
    border-radius: 0px;
    margin: 0% 0% 0% 10%;

} */

@media screen and (max-width: 900px) {
    .content {
        grid-template-rows: 10px [imgStart] 400px [imgEnd] 20px [textStart] 200px [textEnd];
    }

    .content img {
        width: 90vw;
        height: 400px;
        margin: 7% 0% 0% 5%;
    }

    .content p {
        width: 90vw;
        height: 50vh;
        position: relative;
        left: 5%;
        grid-column-start: vline1;
        grid-row-start: textStart;
        grid-row-end: textEnd;
    }
}