img {
    border-radius: 20px;
    box-shadow: 10px 10px 20px lightgray;
    width: 120px;
    height: 250px;
}

.app-screens-container{
    padding: 10px;

    display: flex;
    flex-flow: row wrap;
    gap: 20px;

    /* display: grid;
    grid-template-columns: repeat(4, 360px) */
}

.app-screens{
    position: relative;
}

.app-img{
    position: relative;
}

.app-showcase-title{
    border: 3px solid rgb(127, 192, 214);
    border-radius: 5px;
    color: rgb(106, 186, 212);
    padding: 10px;
    margin: 10px;
    text-align: center;
}

.app-screens-text {
    width: 360px;
    margin-top: 70px;
}