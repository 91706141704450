.our-story-title {
    text-align: center;
    width: 90%;
    border-bottom-style: solid;
    border-bottom-color: lightgreen;
    margin: 2% 0% 0% 5%;
    padding-bottom: 1%;
}

.our-story-title h1 {
    letter-spacing: 5px;
    color: grey;
}

.our-story-content {
    display: grid;
    grid-template-columns: 3% [vline1] 40% [vline2] 150px [vline3];
}

.our-story-content img {
    width: 100%;
    height: 100%;
    margin: 7% 0% 0% 5%;
    grid-column-start: vline1;
    grid-column-end: vline2;
}

.our-story-content p {
    grid-column-start: vline3;
    width: 80%;
    margin-top: 10%;
}

@media screen and (max-width: 900px) {
    .our-story-content {
        grid-template-rows: 10px [imgStart] 400px [imgEnd] 20px [textStart] 200px [textEnd];
    }

    .our-story-content img {
        width: 90vw;
        height: 400px;
        margin: 7% 0% 0% 5%;
    }

    .our-story-content p {
        width: 90vw;
        height: 50vh;
        position: relative;
        left: 5%;
        grid-column-start: vline1;
        grid-row-start: textStart;
        grid-row-end: textEnd;
    }
}