.our-partners-title {
    text-align: center;
    width: 90%;
    border-bottom-style: solid;
    border-bottom-color: lightgreen;
    margin: 2% 0% 0% 5%;
    padding-bottom: 1%;
}

.our-partners-title h1 {
    letter-spacing: 4px;
    color: grey;
}

.our-partners-content {
    display: grid;
    grid-template-columns: 25% repeat(2, [vlinestart] 15% [vlineend] 300px);
    margin-top: 12vh;
}

.our-partners-content img {
    width: 120%;
    height: 120%;
    box-shadow: none;
}

.img-1 {
    grid-column-start: vlinestart 1;
    grid-column-end: vlineend 1;
}

.img-2 {
    grid-column-start: vlinestart 2;
    grid-column-end: vlineend 2;
}

/* .img-3 {
    grid-column-start: vlinestart 3;
    grid-column-end: vlineend 3;
}

.img-4 {
    grid-column-start: vlinestart 4;
    grid-column-end: vlineend 4;
}

.img-5 {
    grid-column-start: vlinestart 5;
    grid-column-end: vlineend 5;
} */

.our-partners-content-text {
    display: grid;
    grid-template-columns: 25% repeat(2, [vlinestart] 15% [vlineend] 100px);
    text-align: center;
    margin-top: 3vh;
}

.text-1 {
    margin-top: 20%;
    grid-column-start: vlinestart 1;
    grid-column-end: vlineend 1;
}

.text-2 {
    grid-column-start: vlinestart 2;
    grid-column-end: vlineend 3;
}

/* .text-3 {
    grid-column-start: vlinestart 3;
    grid-column-end: vlineend 3;
}

.text-4 {
    grid-column-start: vlinestart 4;
    grid-column-end: vlineend 4;
}

.text-5 {
    grid-column-start: vlinestart 5;
    grid-column-end: vlineend 5;
} */