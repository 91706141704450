.Home {
  position: relative;
  width: 100%;
  height: 100vh;
}

.Home__image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: grid;
  place-items: center;
  border-radius: 0px; 
}

.logo {
  margin-top: 5%;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 0px;
  box-shadow: none;
}
/* .Home__image_addon {
  position: absolute;
  bottom: 75%;
  width: 11%;
  height: 11%;
  /* right: 30%; */
/*}*/

.Home__title {
  margin-top: -5%;
  color: white;
  font-size: 400%;
  text-align: center;
  display: flex;
  gap: 1rem;
}

.Home__content {
  margin-top: -7%;
  color: white;
  font-size: 150%;
  text-align: center;
  display: flex;
}

.Home__follow {
  margin-top: -10%;
  color: #ffe4a0;
  font-size: 125%;
  font-style: italic;
  text-align: center;
}

.joinus_title {
  text-align: center;
  color: grey;
}

.cardLogo{
  width: 10%;
  height: 10%;
  box-shadow: none;
  border-radius: 0px;
}

.our-team-title {
  text-align: center;
  width: 90%;
  border-bottom-style: solid;
  border-bottom-color: lightgreen;
  margin: 2% 0% 0% 5%;
  padding-bottom: 1%;
}

.our-team-title h1 {
  letter-spacing: 5px;
  color: grey;
}

.our-team-content {
  display: grid;
  grid-template-columns: 3% [vline1] 40% [vline2] 150px [vline3];
}

.our-team-content img {
  width: 125%;
  height: 400px;
  margin: 18% 0% 0% 5%;
  grid-column-start: vline1;
  grid-column-end: vline2;
}

.our-team-content p {
  grid-column-start: vline3;
  width: 80%;
  margin-top: 10%;
  margin-left: 10%
}

.readmore {
  font-size: 15px;
  margin-top: 5%;
}

@media screen and (max-width: 900px) {
  .our-team-content {
      grid-template-rows: 10px [imgStart] 400px [imgEnd] 20px [textStart] 200px [textEnd];
  }

  .our-team-content img {
      width: 90vw;
      height: 400px;
      margin: 7% 0% 0% 5%;
  }

  .our-team-content p {
      width: 90vw;
      height: 50vh;
      position: relative;
      grid-column-start: vline1;
      grid-row-start: textStart;
      grid-row-end: textEnd;
  }

}